<template>
  <div class="hbi-app-index">
    <van-nav-bar title="项目列表" :fixed="true" :placeholder="true" />
    <div v-if="dataList && dataList.length > 0">
      <el-card class="box-card" v-for="(item, index) of dataList" :key="index">
        <div>
          <div slot="header" class="clearfix">
            <el-alert :title="'项目名称：'+item.projectName" type="success" :closable="false"></el-alert>
          </div>
          <!--通过 column-num 自定义列数-->
          <!--通过 gutter 属性设置格子之间的距离-->
          <!--通过 to 属性设置 vue-router 跳转链接-->
          <div v-if="item.reportList && item.reportList.length > 0">
            <van-grid :column-num="3" :gutter="20">
              <van-grid-item v-for="(item2, index2) of item.reportList" :key="'report_'+index2"
                :icon="require(`../../../../assets/img/report/report1.svg`)" color="#ee0a24" :text="item2.name"
                :to="'/hbi/app/preview?id='+item2.id" />
            </van-grid>
          </div>
          <div v-else>
            <van-empty description="该项目暂未配置报表" image-size="50" />
          </div>
        </div>
      </el-card>
    </div>
    <div v-else>
      <van-empty description="该用户暂未绑定项目" image-size="150" />
    </div>

  </div>
</template>
<script>
import { getList } from '@/api/hbi/app.js'
import { Grid, GridItem, Empty, NavBar } from 'vant';


export default {
  setup () {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },

  data () {
    return {
      dataForm: {
        userId: ''
      },
      dataList: [],
      dataListLoading: false,
    }
  },
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Empty.name]: Empty,
    [NavBar.name]: NavBar
  },
  created () {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.dataForm.userId = this.userId
      getList().then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data

        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },

    // 查看
    previewHandle (id) {
      //重新打开一个页面
      window.open(`/hbi/report/preview?id=${id}`, '_blank')
    },
  }
}
</script>
<style lang="scss">
.hbi-app-index {
  .el-card__body {
    padding: 0 0 10px 0;
  }
  .van-empty {
    padding: 10px 0;
  }
  .van-grid {
    padding: 10px 0 0 20px;
  }
  .van-empty__description {
    margin-top: 2px;
  }
}
</style>